@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Raleway", sans-serif;
  }
}

body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* This prevents horizontal scroll */
}

/*****************************************  For NavBar *****************************************/

.underline-animation::after {
  content: "";
  display: block;
  width: 0;
  height: 2px; /* Adjust the height of the underline as needed */
  background: goldenrod;
  transition: width 0.3s;
}

.underline-animation:hover::after {
  width: 100%;
}

/*************************************************************************************************/

/***************************************** For slideShow *****************************************/

.carousel-image {
  max-height: 500px; /* Adjust as needed */
  width: auto;
  margin: auto; /* This centers the image in the Carousel */
}

/* Add this to your CSS file */
.carousel-item {
  height: 500px; /* Set the height you want for the slideshow */
}

.carousel-item img {
  height: 100%;
  object-fit: cover; /*  cover This will cover the area without stretching the image */
  width: 100vw;
  max-width: none;
}

.carousel-item img {
  /* Your existing styles */
  height: 100%;
  object-fit: cover; /*  cover This will cover the area without stretching the image */
  width: 100vw;
  max-width: none;
}

/*************************************************************************************************/

/********************************************* Headers *******************************************/
.center-bold-gold {
  text-align: center;
  font-weight: bold;
  color: goldenrod;
  font-family: "Courier New", Courier, monospace;
  background-color: black;
  padding: 1ex;
  margin: 0px;
  padding-top: 5rem;
}

/*************************************************************************************************/

/******************************* For the Youtube videos section **********************************/

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  max-width: 100% !important;
  height: auto !important;
  overflow: auto !important;
  margin-bottom: 24px !important;
}

.css-16vq84g-MuiPaper-root-MuiCard-root {
  border: 2px solid rgb(43, 42, 42) !important;
  border-radius: 10px !important;
}

.css-lgk7oi {
  margin-bottom: 2ex;
}

.css-h93ljk-MuiTypography-root {
  font-weight: bold !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.css-qhibcd-MuiContainer-root {
  background: linear-gradient(to bottom, #000000, #4b5563, #000000);
  padding-bottom: 1ex !important;
  padding-top: 1ex !important;
}

.css-lgk7oi {
  width: fit-content;
}

.background {
  background: linear-gradient(to bottom, #000000, #4b5563, #000000);
}
/************************************************************************************************/

/* ****************************** For the Services and Methods *********************************/

.css-1l5bmg7-MuiPaper-root-MuiCard-root {
  max-width: fit-content !important;
  height: 100% !important;
  border: 2px solid rgb(43, 42, 42) !important; /* Border style and color */
  border-radius: 10px !important;
}

.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  color: rgb(248, 240, 240) !important;
  border: 8px solid black !important; /* Border style and color */
  background-color: rgb(17, 10, 10) !important;
  padding: 0ex !important;
  padding-left: 1ex !important;
  padding-right: 1ex !important;
}

.css-r40f8v-MuiTypography-root {
  color: black !important;
  font-size: 15px !important;
}

/*********** For Vercel ************/

.css-u30lmt {
  color: black !important;
  font-size: 15px !important;
}

.css-t1nuxs {
  font-weight: bold !important;
}

.css-156qwxv {
  padding-top: 1ex !important;
  padding-bottom: 1ex !important;
}

.css-1rtnrqa {
  color: rgb(248, 240, 240) !important;
  border: 8px solid black !important; /* Border style and color */
  background-color: rgb(17, 10, 10) !important;
  padding: 0ex !important;
  padding-left: 1ex !important;
  padding-right: 1ex !important;
}

/************************************************************************************************/

/***************************************** For Social Media *************************************/

.list {
  padding-left: 0% !important;
  margin-bottom: 0% !important;
  display: flex;
  flex-direction: row;
}

/** For Footer *****/
.myBackground {
  background-color: black !important;
}
/************************************************************************************************/

/***************************************** For the Reviews *************************************/
.slideshow-container {
  position: relative;
  background: #f1f1f1f1;
}

/* Slides */
.mySlides {
  padding: 80px;
  text-align: center;
  background: linear-gradient(to bottom, black, #202023, black);
  padding-top: 0px;
  padding-bottom: 1px;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 30%;
  width: auto;
  margin-top: -30px;
  padding: 16px;
  color: #9e9999;
  font-weight: bold;
  font-size: 25px;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  position: absolute;
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgb(53, 53, 53);
  color: white;
}

/* The dot/bullet/indicator container */
.dot-container {
  text-align: center;
  padding: 20px;
  background: #ddd;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* Add a background color to the active dot/circle */
.active,
.dot:hover {
  background-color: #717171;
}

/* Add an italic font style to all quotes */
q {
  font-style: italic;
  color: white;
  font-size: large;
}

/* Add a blue color to the author */
.author {
  color: whitesmoke;
}
/************************************************************************************************/
